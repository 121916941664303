<template>
  <div>
    <model title="推荐布局" tag="Flexbox">
      <p>推荐使用 Flexbox（弹性布局） 布局模式。下面是关于Flexbox布局的介绍。</p>
      <p>Flexbox 是 flexible box 的简称（注：意思是“灵活的盒子容器”），是 CSS3 引入的新的布局模式。它决定了元素如何在页面上排列，使它们能在不同的屏幕尺寸和设备下可预测地展现出来。</p>
      <p>它之所以被称为 Flexbox ，是因为它能够扩展和收缩 flex 容器内的元素，以最大限度地填充可用空间。与以前布局方式（如 table 布局和浮动元素内嵌块元素）相比，Flexbox 是一个更强大的方式：</p>
      <div style="margin-bottom:15px">
        ● 在不同方向排列元素<br/>
        ● 重新排列元素的显示顺序<br/>
        ● 更改元素的对齐方式<br/>
        ● 动态地将元素装入容器<br/>
      </div>
      <lh-collapse>
        <div slot="title">基本概念</div>
        <p>采用 Flex 布局的元素，称为 Flex 容器（flex container），简称"容器"。它的所有子元素自动成为容器成员，称为 Flex 项目（flex item），简称"项目"。</p>
        <lh-img :src="require('../../static/flexImg/1-1.png')"></lh-img>
        <div style="margin-bottom:15px">
          在 Flexbox 模型中，有三个核心概念：<br/>
          – flex 项（注：也称 flex 子元素），需要布局的元素<br/>
          – flex 容器，其包含 flex 项<br/>
          – 排列方向（direction），这决定了 flex 项的布局方向
        </div>
      </lh-collapse>
      <lh-collapse>
        <div slot="title">容器属性</div>
        <lh-img :src="require('../../static/flexImg/2-1.png')" width="100%"></lh-img>
        <h4>flex-direction:</h4>
        <div style="margin-bottom:15px">
          ● row（默认值）：主轴为水平方向，起点在左端。<br/>
          ● row-reverse：主轴为水平方向，起点在右端。<br/>
          ● column：主轴为垂直方向，起点在上沿。<br/>
          ● column-reverse：主轴为垂直方向，起点在下沿。
        </div>
        <lh-img :src="require('../../static/flexImg/2-2.png')" width="100%"></lh-img>
        <h4>flex-wrap:</h4>
        <div style="margin-bottom:15px">
          ● nowrap（默认）：不换行。<br/>
          ● wrap：换行，第一行在上方。<br/>
          ● wrap-reverse：换行，第一行在下方。
        </div>
        <h4>justify-content:</h4>
        <div style="margin-bottom:15px">
          ● flex-start（默认值）：左对齐<br/>
          ● flex-end：右对齐<br/>
          ● center： 居中<br/>
          ● space-between：两端对齐，项目之间的间隔都相等。<br/>
          ● space-around：每个项目两侧的间隔相等。所以，项目之间的间隔比项目与边框的间隔大一倍。
        </div>
        <lh-img :src="require('../../static/flexImg/2-3.gif')" width="100%"></lh-img>
        <h4>align-items:</h4>
        <div style="margin-bottom:15px">
          ● flex-start：交叉轴的起点对齐。<br/>
          ● flex-end：交叉轴的终点对齐。<br/>
          ● center：交叉轴的中点对齐。<br/>
          ● baseline: 项目的第一行文字的基线对齐。<br/>
          ● stretch（默认值）：如果项目未设置高度或设为auto，将占满整个容器的高度。
        </div>
        <lh-img :src="require('../../static/flexImg/2-4.gif')" width="100%"></lh-img>
        <h4>align-content:</h4>
        <div style="margin-bottom:15px">
          定义了多根轴线的对齐方式，如果项目只有一根轴线，那么该属性将不起作用<br/>
          ● flex-start：与交叉轴的起点对齐。<br/>
          ● flex-end：与交叉轴的终点对齐。<br/>
          ● center：与交叉轴的中点对齐。<br/>
          ● space-between：与交叉轴两端对齐，轴线之间的间隔平均分布。<br/>
          ● space-around：每根轴线两侧的间隔都相等。所以，轴线之间的间隔比轴线与边框的间隔大一倍。<br/>
          ● stretch（默认值）：轴线占满整个交叉轴。
        </div>
        <lh-img :src="require('../../static/flexImg/2-5.png')" width="100%"></lh-img>
        <h4>结合 justify-content和align-items，看看在 flex-direction 两个不同属性值的作用下，轴心有什么不同：</h4>
        <lh-img :src="require('../../static/flexImg/2-6.gif')" width="100%"></lh-img>
      </lh-collapse>
      <lh-collapse>
        <div slot="title">项目属性</div>
        <lh-img :src="require('../../static/flexImg/3-1.png')" width="100%"></lh-img>
        <h4>order属性：</h4>
        <lh-img :src="require('../../static/flexImg/3-2.png')" width="100%"></lh-img>
        <h4>flex-grow属性：</h4>
        <p>flex-grow属性定义项目的放大比例，默认为0，即如果存在剩余空间，也不放大。</p>
        <p>如果所有项目的flex-grow属性都为1，则它们将等分剩余空间（如果有的话）。如果一个项目的flex-grow属性为2，其他项目都为1，则前者占据的剩余空间将比其他项多一倍。</p>
        <lh-img :src="require('../../static/flexImg/3-3.png')" width="100%"></lh-img>
        <h4>flex-shrink属性：</h4>
        <p>flex-shrink属性定义了项目的缩小比例，默认为1，即如果空间不足，该项目将缩小。</p>
        <lh-code-editor v-model="code1"></lh-code-editor>
        <br/>
        <lh-img :src="require('../../static/flexImg/3-4.jpg')" width="100%"></lh-img>
        <p>如果所有项目的flex-shrink属性都为1，当空间不足时，都将等比例缩小。如果一个项目的flex-shrink属性为0，其他项目都为1，则空间不足时，前者不缩小。</p>
        <p>负值对该属性无效。</p>
        <h4>align-self属性：</h4>
        <p>align-self属性允许单个项目有与其他项目不一样的对齐方式，可覆盖align-items属性。默认值为auto，表示继承父元素的align-items属性，如果没有父元素，则等同于stretch。</p>
        <lh-code-editor v-model="code2"></lh-code-editor>
        <lh-img :src="require('../../static/flexImg/3-5.png')" width="100%"></lh-img>
      </lh-collapse>
      <p>需要注意的是，弹性布局默认不改变项目的宽度，但是它默认改变项目的高度。如果项目没有显式指定高度，就将占据容器的所有高度。lhUI 多数组件都没有显式指定高度，所以使用 flex 布局时请将 align-items 的 stretch（默认值）修改为其他值（flex-start | flex-end | center | baseline）。</p>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"RecommendedLayout",
  data(){
    return{
      code1:
        ".item {\n"+
        "  flex-shrink: <number>; /* default 1 */\n"+
        "}",
      code2:
        ".item {\n"+
        "  align-self: auto | flex-start | flex-end | center | baseline | stretch;\n"+
        "}"
    }
  }
}
</script>

<style lang="less" scoped>
p{
  text-indent: 2em;
}
</style>